$filter: invert(95%);

@mixin dark-mode {
  html & {
    filter: none;

    @media (prefers-color-scheme: dark) {
      filter: $filter;
    }
  }

  html[data-color-scheme="dark"] & {
    filter: $filter;
  }

  html[data-color-scheme="light"] & {
    filter: none;
  }
}
