.status {
  bottom: 0;
  box-sizing: border-box;
  font-size: 1rem;
  left: 0;
  line-height: 2em;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  right: 0;
  position: fixed;
}
