@use '../modules/quisi/dark-mode.scss';

$line-height: 20px;

html {
  font-size: $line-height;
  height: 100%;
  max-height: 100%;
  min-height: 100%;

  filter: none;
  @media (prefers-color-scheme: dark) {
    filter: dark-mode.$filter;
  }
}

html[data-color-scheme="dark"] {
  filter: dark-mode.$filter;
}

html[data-color-scheme="light"] {
  filter: none;
}
