$border-width: 6px;
$min-width: 320px;

.border-inner {
  align-items: stretch;
  border-color: transparent;
  border-bottom-width: 0;
  border-top-width: 0;
  display: flex;
  flex-grow: 1;
  justify-content: stretch;

  @media (max-width: 6in) {
    border-left-width: 0;
    border-right-width: 0;
    border-style: none;
  }

  @media (min-width: 6in) {
    border-left-width: $border-width - 2px;
    border-right-width: $border-width - 2px;
    border-style: solid;
  }
}

.border-outer {
  align-items: stretch;
  border-bottom-width: 0;
  border-top-width: 0;
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  justify-content: stretch;
  max-width: 60em;
  width: 100%;

  @media (max-width: 6in) {
    border-left-width: 0;
    border-right-width: 0;
    border-style: none;
    min-width: $min-width;
  }

  @media (min-width: 6in) {
    border-left-width: 1px;
    border-right-width: 1px;
    border-style: solid;
    min-width: $min-width - 2 * $border-width;
  }
}

.theme {
  border-bottom-width: 0;
  border-top-width: 0;
  box-sizing: border-box;
  padding-bottom: calc(1rem + 1px);
  width: 100%;

  @media (max-width: 6in) {
    border-left-width: 0;
    border-right-width: 0;
    border-style: none;
  }

  @media (min-width: 6in) {
    border-left-width: 1px;
    border-right-width: 1px;
    border-style: solid;
  }
}
